import React from "react";
import PropTypes from "prop-types";
import { Box, Image, Flex, Text } from "@chakra-ui/react";
import { Markup } from "interweave";
import { Link } from "gatsby";

const NewsItem = (props) => {
  const { newsItem } = props;

  const { id, title, image, content, created } = newsItem;

  // const ext = image.url.split(".").pop();
  // new Date(date).toLocaleDateString()
  // const figure = `/static/newsFigures/${id}.${ext}`;

  return (
    <Link to={`/news/${id}`}>
      <Flex
        display="col"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="md"
        height="md"
        align="top"
        _hover={{ border: "1px solid" }}
      >
        <Image
          src={image.url}
          height={200}
          overflow="hidden"
          fit="cover"
          width="100%"
          top="0"
          fallbackSrc="/static/images/microPub_news.png"
        />
        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          ml="2"
          alignItems="center"
        >
          {new Date(Number.parseInt(created)).toLocaleDateString()}
        </Box>
        <Box p="6" align="left">
          <Box mt="1" fontWeight="bold" lineHeight="tight">
            <Markup content={title} />
          </Box>
          <Box>
            <Text fontSize="md">
              <Markup content={content} />
            </Text>
          </Box>
        </Box>
      </Flex>
    </Link>
  );
};

NewsItem.propTypes = {
  newsItem: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({ url: PropTypes.string }),
  }),
};

NewsItem.defaultProps = {
  newsItem: null,
};

export default NewsItem;
