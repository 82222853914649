import React from "react";
import { Link, ListItem, UnorderedList } from "@chakra-ui/react";

import Section from "../ui/common/Section";
import Paragraph from "../ui/common/Paragraph";

const items = [
  {
    title: "Reviewer Guidelines - Overview",
    text: (
      <>
        <Paragraph>
          microPublications are single research findings with the report
          containing a brief textual description of the experiment, methods, and
          controls, usually containing a single figure and/or table.
          <i>microPublication Biology</i> accepts all experimental findings,
          independent of subjective evaluation of its perceived impact on the
          field. Peer review is employed by <i>microPublication Biology</i> to
          assess whether the microPublication is technically sound and the
          conclusions justified. Given these criteria, review by a scientist in
          the field is expected to require minimal time and effort.
        </Paragraph>
        <Paragraph>
          It is not generally expected that the authors would need to perform
          additional experiments. However, on occasion important
          data/information that is necessary for solidifying a conclusion is
          missing from a submitted manuscript. In such a case, the reviewer
          should request the relevant information. Often, the authors have the
          data or will perform the relevant experiment.
        </Paragraph>
        <Paragraph>
          If the data/information is not available, the outcome depends on the
          nature of the missing data. If the data is viewed as essential for the
          authors to make the conclusion (e.g., control data missing) then the
          manuscript should be rejected. If the data is sound and a conclusion
          can be drawn, but the generality or extensibility of the result is not
          shown, then a qualification is required; for example, if a novel
          phenotype is observed in a single allele, but not examined in the
          reference allele, a qualification should be added to indicate that
          current data does not distinguish whether the result is specific for
          the allele tested or a general property of the gene. Here,{" "}
          <i>microPublication Biology</i> believes that getting findings rapidly
          out to the community assists in advancing the scientific mission, but
          that it is important to be transparent about limitations that make a
          conclusion preliminary.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Invited to Review a microPublication",
    text: (
      <>
        <Paragraph>
          If the Science Officer or Editorial Staff has selected you as a
          potential reviewer, you will receive an Invitation Email to review the
          article. That Invitation Email contains the paper title, instructions,
          signup email address, and a link to the microPublication Portal.
        </Paragraph>
        <Paragraph>
          First time reviewers need to create an account by clicking on ‘sign
          up’ where you provide your Name, User Name, and Email Address. Enter
          the email address specified in the reviewer invite message. Check your
          inbox for a validation email to the address you used to set up your
          account and follow the instructions. NOTE: you will not be able to log
          in until you have verified your email.
        </Paragraph>
        <Paragraph>
          Once in your account, you will see the article you were invited to
          review on your Dashboard. You can see a preview of the article by
          clicking preview, you will not be committed to reviewing the article
          by previewing it.
        </Paragraph>
        <Paragraph>
          If you want to review the article click Accept, and you will be
          prompted to declare any Conflict of Interest(COI). Please Click
          Decline if you do have a COI or would rather not review the article.
        </Paragraph>
        <Paragraph>
          Once you have accepted the option to review the submission expect a
          reminder in 3 days if you were unable to finish your review. If we do
          not hear from you within a week we may uninvite you and move on to
          invite another reviewer.
        </Paragraph>
        <Paragraph>
          When you see the article there will be a panel beside the article for
          you to use to submit your review along with a space to send
          confidential comments to the editors. You will also have decision
          buttons about the article to relay your suggestions for acceptance,
          conditional acceptance, and rejection to the editor.
        </Paragraph>
        <Paragraph>
          If you are invited to participate in a Review Panel, follow
          instructions from the Review Panel leader.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Evaluating a microPublication",
    text: (
      <>
        <Paragraph>
          Reviewers should evaluate the microPublication for the following:
        </Paragraph>
        <UnorderedList marginLeft="50px">
          <ListItem>
            Are results technically sound and adhere to reporting guidelines and
            community standards?
          </ListItem>
          <ListItem>
            Do those data, and related information (e.g. statistical analysis)
            support the conclusions drawn?
          </ListItem>
          <ListItem>
            Are the experiments sufficiently explained/referenced so that the
            findings can be reproduced by other researchers?
          </ListItem>
          <ListItem>
            Is the microPublication presented in a logical progression, in
            standard English and with appropriate nomenclature?
          </ListItem>
        </UnorderedList>
      </>
    ),
  },
  {
    title: "Decision Options",
    text: (
      <>
        <Paragraph>
          As a reviewer, you have a number of options to recommend concerning
          the article. The options are explained below.
        </Paragraph>
        <UnorderedList marginLeft="50px">
          <ListItem>
            Accept. This option will be interpreted as accept-as-is.
          </ListItem>
          <ListItem>
            Accept, with minor modifications to figure and/or text
          </ListItem>
          <ListItem>
            Accept, with major modifications to figure and/or text
          </ListItem>
          <ListItem>
            Accept, with either an additional data/experiment or include a
            caveat concerning the conclusion given the missing information (as
            well as any additional minor or major modifications)
          </ListItem>
          <ListItem>
            Accept, with the addition of missing essential data; in the absence
            of this data, then Reject
          </ListItem>
          <ListItem>Reject</ListItem>
        </UnorderedList>
        <Paragraph>
          Be sure to detail any suggested modifications to the article in the
          Reviewer space. If modifications are requested, enumerate each point
          the author(s) should address.
        </Paragraph>
        <Paragraph>
          The Editors and Science Officer reserve the right to pass along any or
          all of the suggestions you have for the author.
        </Paragraph>
        <Paragraph>
          If you want to convey comments only to the Editor and not to the
          author, be sure to enter those comments to the “
          <b>Confidential comments to the editor</b>” section. Comments could
          include concerns about the data quality, issues related to plagiarism,
          inappropriate figure manipulation, etc. These comments will not be
          shown to the author(s).
        </Paragraph>
      </>
    ),
  },
  {
    title: "Open Acknowledgement",
    text: (
      <>
        <Paragraph>
          Peer-reviewers can choose <b>Open Acknowledgement</b> — a new metric
          for researcher engagement and value in their field – which will be
          included in the published article.
        </Paragraph>
        <Paragraph>
          If a reviewer does not opt in for open acknowledgement they will be
          automatically listed as Anonymous.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Too Busy to Review or Not Interested in Reviewing?",
    text: (
      <>
        <Paragraph>
          If you are too busy to review the article please Decline to review as
          soon as possible in your Dashboard. We will move on to invite another
          reviewer and your access to the paper will discontinue.
        </Paragraph>
        <Paragraph>
          You can also suggest a senior graduate student or a postdoctoral
          fellow in your group who has sufficient expertise and could review the
          submission.
        </Paragraph>
        <Paragraph>
          If you do not respond to the initial invitation or are delayed in
          responding, you will receive a reminder invite after 5 days, again
          with a link to the article. If, within another 3 days, you still do
          not respond, you will be uninvited and you will no longer be able to
          access the paper as another researcher will be invited to be the
          reviewer.
        </Paragraph>
      </>
    ),
  },

  {
    title: "Become a Reviewer",
    text: (
      <>
        <Paragraph>
          Reviewing microPublications is intended to be quick -– as short as 15
          minutes depending on the article.
        </Paragraph>

        <Paragraph>
          If you are interested in becoming a reviewer for microPublication
          Biology create an account{" "}
          <Link href="https://portal.micropublication.org/signup" isExternal>
            here
          </Link>
          .
          <br />
          To be a reviewer you need to be a Principal Investigator (PI).
          Postdocs and senior graduate students are also allowed to review
          manuscripts pending approval of their PI.
        </Paragraph>

        <Paragraph>
          Questions? Contact us{" "}
          <Link href="mailto:contact@micropublication.org">
            contact@micropublication.org
          </Link>
        </Paragraph>
      </>
    ),
  },
  {
    title: "Troubles Logging in to Reviewer's Dashboard",
    text: (
      <UnorderedList>
        <ListItem>
          Check your Invitation email message and make sure you set up your
          account with the address in the message.
        </ListItem>
        <ListItem>You may need to reset your password.</ListItem>
        <ListItem>
          Check your inbox for a validation email to the email address you used
          to set up your account and follow the instructions. NOTE: you will not
          be able to log in until you have verified your email.
        </ListItem>
      </UnorderedList>
    ),
  },
];

const ReviewerSection = (props) => {
  const { reviewersRef, isPage } = props;
  return (
    <Section
      items={items}
      title="For Reviewers"
      sectionRef={reviewersRef}
      isPage={isPage}
    />
  );
};

export default ReviewerSection;
