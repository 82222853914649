import React from "react";
import {
  Center,
  Container,
  Heading,
  Stack,
  Box,
  Image,
  Link,
  Text,
  Divider,
  Spacer,
} from "@chakra-ui/react";

const ContactSection = (props) => {
  const { contactRef } = props;
  return (
    <Center>
      <Container
        maxW="7xl"
        borderWidth="1px"
        borderRadius="lg"
        p="15vh"
        ref={contactRef}
      >
        <Heading>Contact us</Heading>
        <Stack>
          <Stack direction="row">
            <Box>
              <Heading size="md">Write us</Heading>
              <Text>
                For general questions about microPublication:
                <br />
                <Link href="mailto:contact@micropublication.org">
                  contact@micropublication.org
                </Link>
              </Text>
              <Text>
                For specific questions about our platform or a submission:
                <br />
                <Link href="mailto:editors@micropublication.org">
                  editors@micropublication.org
                </Link>
              </Text>
            </Box>
            <Spacer />
            <Box>
              <Heading size="md">Follow us on Twitter</Heading>
              <Link href="https://twitter.com/micropub7n" isExternal>
                @micropub7n
              </Link>
            </Box>
          </Stack>
          <Divider />
          <Stack direction="row">
            <Box>
              <Heading size="md">Editorial office</Heading>
              <Text>
                MC: 140-18
                <br />
                1200 E. California Blvd.
                <br />
                Pasadena, CA
                <br />
                91125
              </Text>
            </Box>
            <Spacer />
            <Image src="/static/images/Caltech_pict-300x213.png" />
          </Stack>
        </Stack>
      </Container>
    </Center>
  );
};

export default ContactSection;
