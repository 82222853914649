import React from "react";
import {
  Heading,
  Image,
  Wrap,
  WrapItem,
  Text,
  Stack,
  Box,
  Link,
  Center,
} from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";

const partnerImages = [
  {
    src: "wormbase-small.png",
    alt: "WormBase",
    url: "https://wormbase.org",
  },
  {
    src: "flybase-small.png",
    alt: "FlyBase",
    url: "https://flybase.org",
  },
  {
    src: "xenbase-small.png",
    alt: "Xenbase",
    url: "https://xenbase.org",
  },
  {
    src: "tair-small.png",
    alt: "TAIR",
    url: "https://arabidopsis.org",
  },
  {
    src: "sgd-small.png",
    alt: "SGD",
    url: "https://yeastgenome.org",
  },
  {
    src: "zfin-small.png",
    alt: "ZFIN",
    url: "https://zfin.org",
  },
  {
    src: "pombase-small.png",
    alt: "PomBase",
    url: "https://pombase.org",
  },
  {
    src: "mgi_logo.gif",
    alt: "MGI",
    url: "http://informatics.jax.org",
  },
  {
    src: "dicty_logo.gif",
    alt: "dictyBase",
    url: "http://dictybase.org",
  },
  {
    src: "maizegdb.png",
    alt: "MaizeGDB",
    url: "https://maizegdb.org",
  },
  {
    src: "proteopedia.png",
    alt: "Proteopedia",
    url: "https://proteopedia.org",
  },
  {
    src: "caltech_data.png",
    alt: "Caltech Data",
    url: "https://data.caltech.edu",
  },
  {
    src: "ncbi.jpg",
    alt: "NCBI",
    url: "https://www.ncbi.nlm.nih.gov",
  },
  {
    src: "rgd-logo.png",
    alt: "RGD",
    url: "https://rgd.mcw.edu",
  },
  {
    src: "sol-genomics.png",
    alt: "Sol Genomics Network",
    url: "https://solgenomics.net",
  },
  {
    src: "echinobase.png",
    alt: "Echinobase",
    url: "https://www.echinobase.org",
  },
  {
    src: "soybase-small.jpg",
    alt: "SoyBase",
    url: "https://soybase.org",
  },
  {
    src: "agbiodata-big.png",
    alt: "AgBioData",
    url: "https://www.agbiodata.org",
  },
];

const imageDir = "/static/images/partners/";

const Partners = (props) => {
  const { partnersRef } = props;
  return (
    <Center>
      <Stack
        maxW="7xl"
        borderWidth="1px"
        borderRadius="lg"
        p="10vh"
        ref={partnersRef}
      >
        <Heading size="lg" color="gray">
          Partner Databases and Communities
        </Heading>
        <Text fontSize="xl">
          microPublication streamlines data curation. We directly deposit
          atomized data from each microPublication to our{" "}
          <GatsbyLink to="/data-sharing-policy">
            <u>Partner Databases and Communities</u>
          </GatsbyLink>
          .
        </Text>
        <Wrap justify="center">
          {partnerImages.map((image, index) => {
            return (
              <WrapItem key={`partner-${index}`}>
                <Link href={image.url} target="_blank">
                  <Box as="button">
                    <Image
                      alt={image.alt}
                      src={`${imageDir}${image.src}`}
                      height="70px"
                    />
                  </Box>
                </Link>
              </WrapItem>
            );
          })}
        </Wrap>
      </Stack>
    </Center>
  );
};

export default Partners;
