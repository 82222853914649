import React, { useRef, useState, useEffect } from "react";

import MainPage from "../ui/mainPage/MainPage";
import Layout from "../components/Layout";

const MainPageTemplate = (props) => {
  const { pageContext } = props;

  const {
    recentArticles,
    popularArticles,
    speciesOptions,
    news,
    categoryOptions,
    mostCitedArticles,
  } = pageContext;

  const [popularTab, setPopularTab] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("popularTab")) {
      setPopularTab(Number(localStorage.getItem("popularTab")));
    }
  }, []);

  const savePopularTab = (tabIndex) => {
    localStorage.setItem("popularTab", tabIndex);
    setPopularTab(tabIndex);
  };

  const refs = {
    popularArticlesRef: useRef(),
    recentArticlesRef: useRef(),
    aboutRef: useRef(),
    authorsRef: useRef(),
    reviewersRef: useRef(),
    contactRef: useRef(),
    speciesRef: useRef(),
    partnersRef: useRef(),
    publishRef: useRef(),
    newsRef: useRef(),
  };

  const main = (
    <MainPage
      mostCited={mostCitedArticles}
      recent={recentArticles}
      popular={popularArticles}
      refs={refs}
      speciesOptions={speciesOptions}
      categories={categoryOptions}
      news={news}
      popularTab={popularTab}
      savePopularTab={savePopularTab}
    />
  );

  return <Layout children={main} refs={refs} main />;
};

export default MainPageTemplate;
