import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Wrap,
  WrapItem,
  Button,
  Stack,
  Heading,
  Flex,
  Center,
} from "@chakra-ui/react";

import NewsItem from "./NewsItem";

const NewsList = (props) => {
  const { news, newsRef } = props;

  const [offset, setOffset] = useState(0);
  const pageSize = "12";
  const [newsPage, setNewsPage] = useState(news.slice(0, pageSize));

  const increaseOffset = () => {
    setNewsPage(news.slice(0, offset + pageSize + pageSize));
    setOffset(offset + pageSize);
  };

  if (!news || news.length === 0)
    return (
      <Stack borderWidth="1px" maxWidth="7xl" borderRadius="lg" p="10vh">
        <Heading size="lg" color="gray">
          There is no news to display
        </Heading>
      </Stack>
    );

  return (
    <Center paddingTop="100px">
      <Stack
        borderWidth="1px"
        maxWidth="7xl"
        borderRadius="lg"
        p="2em"
        ref={newsRef}
      >
        <Heading size="lg" color="gray">
          microPublication News
        </Heading>

        <Wrap>
          {newsPage.map((newsItem, index) => (
            <WrapItem key={`news-item-${index}`}>
              <NewsItem newsItem={newsItem} />
            </WrapItem>
          ))}
        </Wrap>

        <Flex justify="flex-end">
          <Button
            margin="1em"
            onClick={increaseOffset}
            disabled={offset >= news.length}
            float="right"
          >
            Load more news...
          </Button>
        </Flex>
      </Stack>
    </Center>
  );
};

NewsList.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.shape({ url: PropTypes.string }),
    })
  ),
};
NewsList.defaultProps = {
  news: [],
};

export default NewsList;
